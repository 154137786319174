
import Vue from "vue";
import api from "@/services/dataService";
import gmapsInit from "@/services/mapService";
import notification from "@/services/notificationService";
import { DateTime, Duration } from "luxon";
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";

export default Vue.extend({
  name: "TripDetail",
  components: { VueJsonPretty },
  data() {
    return {
      DateTime,
      Duration,
      tripId: undefined,
      selected: undefined,
      gMapInfoWindow: undefined as any,
      markersGmapObjsArray: [] as any,
      map: undefined as undefined | any,
      locationInPayload: false,
      tableProps: {
        isPaginated: false,
        isPaginationSimple: false,
        paginationPosition: "bottom",
        defaultSortDirection: "asc",
        sortIcon: "arrow-up",
        sortIconSize: "is-small",
        currentPage: 1,
        perPage: 10
      },
      isLoading: false
    };
  },
  computed: {
    selectedTenant() {
      return this.$store.state.selectedTenant;
    },
    tripData() {
      return this.$store.state.tripData;
    },
    tripDetail() {
      return this.$store.state.tripDetail;
    },
    tripDuration(): string {
      const duration = Duration.fromObject(
        DateTime.fromISO(this.tripDetail.endTime)
          .diff(DateTime.fromISO(this.tripDetail.startTime), "hours")
          .toObject()
      );
      let formattedDuration = undefined;
      if (duration.hours > 1) {
        formattedDuration = duration.toFormat("hh Hr mm") + " Mins";
      } else {
        formattedDuration = duration.toFormat("mm") + " Mins";
      }
      return formattedDuration;
    }
  },
  watch: {
    selectedTenant: function () {
      this.initializeView();
    },
    tripData(newData) {
      // Check if there are any location in payload
      if (newData.some((e: { LONGITUDE: number }) => e.LONGITUDE)) {
        this.locationInPayload = true;
      } else {
        this.locationInPayload = false;
      }
      if (this.locationInPayload) {
        this.isLoading = true;
        this.loadMap();
      }
    }
  },
  async created() {
    this.initializeView();
  },
  methods: {
    async initializeView() {
      if (this.selectedTenant) {
        await this.$store.dispatch("setTripData", undefined);
        if (this.$route.query.tripId && this.$route.query.vehicleId) {
          this.getTripData(this.$route.query.tripId.toString());
        }
      }
    },
    getTripData(tripId: string) {
      if (!this.$route.query.tripId) {
        this.$router.replace({
          query: {
            tripId: tripId,
            vehicleId: this.$route.query.vehicleId
          }
        });
      }
      this.$store.dispatch("getTripData", {
        selectedTenant: this.selectedTenant,
        tripId: tripId
      });
      this.$store.dispatch("getTripDetail", {
        tripId: this.$route.query.tripId,
        vehicleId: this.$route.query.vehicleId,
        selectedTenant: this.selectedTenant
      });
    },
    async loadMap() {
      // empty the markers array to clear previously
      // pushed markers
      this.markersGmapObjsArray = [];
      try {
        if (this.tripData?.length) {
          let isMapReady = false;
          const google: any = await gmapsInit();
          const geocoder = new google.maps.Geocoder();
          this.gMapInfoWindow = new google.maps.InfoWindow();

          for (let i = 0; i < this.tripData.length; i++) {
            const tripDataRecord = this.tripData[i];
            if (tripDataRecord.LATITUDE && tripDataRecord.LONGITUDE) {
              if (!isMapReady) {
                this.map = new google.maps.Map(
                  document.getElementById("map") as HTMLElement,
                  {
                    center: {
                      lat: tripDataRecord.LATITUDE,
                      lng: tripDataRecord.LONGITUDE
                    },
                    zoom: 11,
                    mapTypeControl: true,
                    mapTypeControlOptions: {
                      style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
                      mapTypeIds: ["roadmap", "terrain"]
                    }
                  }
                );
                // mark map ready!
                isMapReady = true;
              }

              // now create markers

              const m = new google.maps.Marker({
                position: {
                  lat: tripDataRecord.LATITUDE,
                  lng: tripDataRecord.LONGITUDE
                },
                map: this.map,
                icon: {
                  url: require("@/assets/img/map_marker.svg"),
                  scaledSize: new google.maps.Size(20, 20)
                },
                // scaledSize: new google.maps.Size(20, 20),
                // custom attributes in this obj
                receivedAt: tripDataRecord.receivedAt,
                recordedAt: tripDataRecord.recordedAt,
                payloadId: tripDataRecord.payloadId,
                // store the coordiantes separately as well in marker obj as we use it to show text in infowindow
                lat: tripDataRecord.LATITUDE,
                lng: tripDataRecord.LONGITUDE
              });
              // also store the created marker in markersGmapObjsArray
              this.markersGmapObjsArray.push(m);
              google.maps.event.addListener(
                m,
                "click",
                ((marker, i) => {
                  return () => {
                    this.gMapInfoWindow.setContent(`
                  Lat: ${m.lat}
                  <br/>
                  Lng: ${m.lng}
                  <br/>
                  Received At: ${m.receivedAt}
                  <br/>
                  Recorded At: ${m.recordedAt}
                `);
                    this.gMapInfoWindow.open(this.map, marker);
                  };
                })(m, i)
              );
            }
          }

          const path = new google.maps.Polyline({
            path: this.markersGmapObjsArray.map((m: any) => ({
              lat: m.lat,
              lng: m.lng
            })),
            geodesic: true,
            strokeColor: "#FF0000",
            strokeOpacity: 1.0,
            strokeWeight: 2
          });

          // Setting map to display all markers via fitBounds()
          const latlngbounds = new google.maps.LatLngBounds();
          for (let i = 0; i < this.markersGmapObjsArray.length; i++) {
            latlngbounds.extend(this.markersGmapObjsArray[i]);
          }
          if (this.map) {
            this.map.fitBounds(latlngbounds);
          }
          path.setMap(this.map);
        }
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        console.error(err);
        notification.error(err.message);
      }
    },
    async addInfoWindow(e: any) {
      console.log("e", e);
      // get selected marker
      const m = this.markersGmapObjsArray.find(
        (x: any) => x.payloadId === e.payloadId
      );
      console.log("e", "m", m);
      if (m) {
        this.gMapInfoWindow.setContent(`
        Lat: ${m.lat}
        <br/>
        Lng: ${m.lng}
        <br/>
        Received At: ${m.receivedAt}
        <br/>
        Recorded At: ${m.recordedAt}
      `);
        this.gMapInfoWindow.open(this.map, m);
      }
    }
  }
});
